import { render, staticRenderFns } from "./DataCounterRound.vue?vue&type=template&id=7b2db130&scoped=true&"
import script from "./DataCounterRound.vue?vue&type=script&lang=js&"
export * from "./DataCounterRound.vue?vue&type=script&lang=js&"
import style0 from "./DataCounterRound.vue?vue&type=style&index=0&id=7b2db130&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b2db130",
  null
  
)

export default component.exports